import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import { IconWithTextPropType } from '../propTypes'
import CareerTile from './CareerTile'

const CareerTiles = ({ header, headerColored, tiles, tilesMobile }) =>
  <SectionWrapper className='career-tiles'>
    <div className='career-tiles__header'>
      <span>{header}</span>
      <span>{headerColored}</span>
    </div>
    <iframe 
      src="https://senprima.imployo.de/?teamplayer_embed=195" 
      className='imployo-iframe' 
      title="Imployo iframe"
    />
    <CareerTile
      tiles={tiles}
      className='career-tiles__tiles'
    />
    <CareerTile
      tiles={tilesMobile}
      className='career-tiles__tiles-mobile'
    />
  </SectionWrapper>

export default CareerTiles

CareerTiles.propTypes = {
  header: PropTypes.string,
  headerColored: PropTypes.string,
  tiles: PropTypes.arrayOf(IconWithTextPropType)
}

CareerTiles.defaultProps = {
  header: '',
  headerColored: '',
  tiles: []
}
